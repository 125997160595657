var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModuleComponent',{attrs:{"module-data":_vm.moduleData},on:{"changed":function($event){_vm.item = $event}},scopedSlots:_vm._u([{key:"customColumn_active",fn:function(ref){
var rowItem = ref.rowItem;
return [_c('TableStatusColumn',{model:{value:(rowItem.active),callback:function ($$v) {_vm.$set(rowItem, "active", $$v)},expression:"rowItem.active"}})]}},{key:"tableFilters",fn:function(ref){
var filters = ref.filters;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.FARMER')}},[_c('v-select2',{attrs:{"endPoint":"accounts","placeholder":_vm.t('GLOBAL.FARMER'),"filters":{
                accountTypeId: { value: _vm.Constants.AccountTypes.Farmer }
              },"sort":"name"},on:{"input":function($event){filters.landId.value = filters.cropLandId.value = null}},model:{value:(filters.farmerId.value),callback:function ($$v) {_vm.$set(filters.farmerId, "value", $$v)},expression:"filters.farmerId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.LAND')}},[_c('v-select2',{attrs:{"endPoint":"lands","placeholder":_vm.t('GLOBAL.LAND'),"sort":"name","filters":{ farmerId: { value: filters.farmerId.value } }},on:{"input":function($event){filters.cropLandId.value = null}},model:{value:(filters.landId.value),callback:function ($$v) {_vm.$set(filters.landId, "value", $$v)},expression:"filters.landId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CROPLAND')}},[_c('v-select2',{attrs:{"endPoint":"cropLands","placeholder":_vm.t('GLOBAL.CROPLAND'),"sort":"name","filters":{ landId: { value: filters.landId.value } }},model:{value:(filters.cropLandId.value),callback:function ($$v) {_vm.$set(filters.cropLandId, "value", $$v)},expression:"filters.cropLandId.value"}})],1)],1)],1)],1)]}},{key:"form",fn:function(ref){
              var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.FARMER')}},[_c('v-select2',{attrs:{"text":item.cropLand &&
              item.cropLand.land &&
              item.cropLand.land.farmer &&
              item.cropLand.land.farmer.account
                ? item.cropLand.land.farmer.account.name
                : '',"endPoint":"accounts","placeholder":_vm.t('GLOBAL.FARMER'),"filters":{
              accountTypeId: { value: _vm.Constants.AccountTypes.Farmer }
            },"sort":"name"},on:{"input":function($event){_vm.landId = item.cropLandId = null}},model:{value:(_vm.farmerId),callback:function ($$v) {_vm.farmerId=$$v},expression:"farmerId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.LAND')}},[_c('v-select2',{attrs:{"text":item.cropLand && item.cropLand.land
                ? item.cropLand.land.name
                : '',"endPoint":"lands","placeholder":_vm.t('GLOBAL.LAND'),"sort":"name","filters":{ farmerId: { value: _vm.farmerId } }},on:{"input":function($event){item.cropLandId = null}},model:{value:(_vm.landId),callback:function ($$v) {_vm.landId=$$v},expression:"landId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CROPLAND')}},[_c('v-select2',{attrs:{"text":item.cropLand ? item.cropLand.name : '',"endPoint":"cropLands","placeholder":_vm.t('GLOBAL.CROPLAND'),"sort":"name","filters":{ landId: { value: _vm.landId } }},model:{value:(item.cropLandId),callback:function ($$v) {_vm.$set(item, "cropLandId", $$v)},expression:"item.cropLandId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.ACTIONTYPE')}},[_c('v-select2',{attrs:{"text":item.actionType ? item.actionType.name : '',"endPoint":"definitions","sort":"name","filters":{
              definitionTypeId: {
                value: _vm.Constants.DefinitionTypes.CropLandActions
              }
            }},model:{value:(item.actionTypeId),callback:function ($$v) {_vm.$set(item, "actionTypeId", $$v)},expression:"item.actionTypeId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.ACTIONPRODUCT')}},[_c('v-select2',{attrs:{"text":item.actionProduct ? item.actionProduct.name : '',"endPoint":"definitions","sort":"name","filters":{
              definitionTypeId: {
                value: _vm.Constants.DefinitionTypes.CropLandActionProducts
              },
              parentDefinitionId: {
                value: item.actionTypeId
              }
            }},model:{value:(item.actionProductId),callback:function ($$v) {_vm.$set(item, "actionProductId", $$v)},expression:"item.actionProductId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.DATE')}},[_c('BDatePicker',{attrs:{"id":'date',"hide-header":"","show-decade-nav":"","placeholder":_vm.t('GLOBAL.DATE')},on:{"input":function($event){item.date = $event}},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.AMOUNT')}},[_c('b-input-group',[_c('decimal-input',{model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}}),_c('b-input-group-append',[_c('b-input-group-text',[_vm._v("miktar/adet")])],1)],1)],1)],1)],1)]}},{key:"customColumn_cropLand",fn:function(ref){
            var rowItem = ref.rowItem;
return [_c('span',{staticClass:"badge badge-primary mb-1"},[_vm._v(_vm._s(_vm.t("GLOBAL.FARMER")))]),_vm._v(" "+_vm._s(rowItem.cropLand.land.farmer.account.name)),_c('br'),_c('span',{staticClass:"badge brown lighten-1 white--text mb-1"},[_vm._v(_vm._s(_vm.t("GLOBAL.LAND")))]),_vm._v(" "+_vm._s(rowItem.cropLand.land.name)+" ("+_vm._s(rowItem.cropLand.land.area)+") "),_c('br'),_c('span',{staticClass:"badge green lighten-2 white--text"},[_vm._v(_vm._s(_vm.t("GLOBAL.CROPLAND")))]),_vm._v(" "+_vm._s(rowItem.cropLand.name)+" ("+_vm._s(rowItem.cropLand.area)+") ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }