<template>
  <ModuleComponent @changed="item = $event" :module-data="moduleData">
    <template v-slot:customColumn_active="{ rowItem }">
      <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
    </template>
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.FARMER')">
              <v-select2
                v-model="filters.farmerId.value"
                endPoint="accounts"
                :placeholder="t('GLOBAL.FARMER')"
                :filters="{
                  accountTypeId: { value: Constants.AccountTypes.Farmer }
                }"
                sort="name"
                @input="filters.landId.value = filters.cropLandId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.LAND')">
              <v-select2
                v-model="filters.landId.value"
                endPoint="lands"
                :placeholder="t('GLOBAL.LAND')"
                sort="name"
                :filters="{ farmerId: { value: filters.farmerId.value } }"
                @input="filters.cropLandId.value = null"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="3" sm="3" md="3">
            <b-form-group :label="t('GLOBAL.CROPLAND')">
              <v-select2
                v-model="filters.cropLandId.value"
                endPoint="cropLands"
                :placeholder="t('GLOBAL.CROPLAND')"
                sort="name"
                :filters="{ landId: { value: filters.landId.value } }"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:form="{ item }">
      <b-row>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.FARMER')">
            <v-select2
              v-model="farmerId"
              :text="
                item.cropLand &&
                item.cropLand.land &&
                item.cropLand.land.farmer &&
                item.cropLand.land.farmer.account
                  ? item.cropLand.land.farmer.account.name
                  : ''
              "
              endPoint="accounts"
              :placeholder="t('GLOBAL.FARMER')"
              :filters="{
                accountTypeId: { value: Constants.AccountTypes.Farmer }
              }"
              sort="name"
              @input="landId = item.cropLandId = null"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.LAND')">
            <v-select2
              v-model="landId"
              :text="
                item.cropLand && item.cropLand.land
                  ? item.cropLand.land.name
                  : ''
              "
              endPoint="lands"
              :placeholder="t('GLOBAL.LAND')"
              sort="name"
              :filters="{ farmerId: { value: farmerId } }"
              @input="item.cropLandId = null"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.CROPLAND')">
            <v-select2
              v-model="item.cropLandId"
              :text="item.cropLand ? item.cropLand.name : ''"
              endPoint="cropLands"
              :placeholder="t('GLOBAL.CROPLAND')"
              sort="name"
              :filters="{ landId: { value: landId } }"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.ACTIONTYPE')">
            <v-select2
              v-model="item.actionTypeId"
              :text="item.actionType ? item.actionType.name : ''"
              endPoint="definitions"
              sort="name"
              :filters="{
                definitionTypeId: {
                  value: Constants.DefinitionTypes.CropLandActions
                }
              }"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.ACTIONPRODUCT')">
            <v-select2
              v-model="item.actionProductId"
              :text="item.actionProduct ? item.actionProduct.name : ''"
              endPoint="definitions"
              sort="name"
              :filters="{
                definitionTypeId: {
                  value: Constants.DefinitionTypes.CropLandActionProducts
                },
                parentDefinitionId: {
                  value: item.actionTypeId
                }
              }"
            ></v-select2>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.DATE')">
            <BDatePicker
              :id="'date'"
              v-model="item.date"
              @input="item.date = $event"
              hide-header
              show-decade-nav
              :placeholder="t('GLOBAL.DATE')"
            >
            </BDatePicker>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6">
          <b-form-group :label="t('GLOBAL.AMOUNT')">
            <b-input-group>
              <decimal-input v-model="item.amount" />
              <b-input-group-append>
                <b-input-group-text>miktar/adet</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template v-slot:customColumn_cropLand="{ rowItem }">
      <span class="badge badge-primary mb-1">{{ t("GLOBAL.FARMER") }}</span>
      {{ rowItem.cropLand.land.farmer.account.name }}<br />
      <span class="badge brown lighten-1 white--text mb-1">{{
        t("GLOBAL.LAND")
      }}</span>
      {{ rowItem.cropLand.land.name }} ({{ rowItem.cropLand.land.area }}) <br />
      <span class="badge green lighten-2 white--text">{{
        t("GLOBAL.CROPLAND")
      }}</span>
      {{ rowItem.cropLand.name }} ({{ rowItem.cropLand.area }})
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-CropLandAction
export default {
  name: "cropLandActions",
  data() {
    return {
      item: {},
      moduleData: {
        name: "cropLandActions",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
				            id,
				            cropLand{
					            name,
					            cropLandType{name},
					            land{
					                farmer{account{name}},
				                    name,
				                    area
			                    },
			                    productKind{
					                name,
					                product{name}
			                    },
				            },
				            actionType{name},
				            actionProduct{name},
				            date,
				            amount
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.CROPLAND.D"), value: "cropLand" },
            { text: this.t("GLOBAL.ACTIONTYPE.S"), value: "actionType.name" },
            { text: this.t("GLOBAL.PRODUCT.D"), value: "actionProduct.name" },
            { text: this.t("GLOBAL.DATE.D"), value: "date" },
            { text: this.t("GLOBAL.AMOUNT"), value: "amount" }
          ],
          customColumns: ["cropLand"],
          filters: {
            farmerId: {
              field: "cropland.land.farmer.id",
              type: "number",
              value: null
            },
            landId: {
              field: "cropland.land.id",
              type: "number",
              value: null
            },
            cropLandId: {
              field: "cropland.id",
              type: "number",
              value: null
            },
            email: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            objectId: {
              type: "number",
              value: null
            }
          }
        },
        form: {
          maxWidth: "80%"
        }
      },
      farmerId: 0,
      landId: 0
    };
  },
  watch: {
    item(item) {
      this.farmerId =
        item.cropLand &&
        item.cropLand.land &&
        item.cropLand.land.farmer &&
        item.cropLand.land.farmer.account
          ? item.cropLand.land.farmer.account.id
          : this.farmerId;
      this.landId =
        item.cropLand && item.cropLand.land && item.cropLand.land
          ? item.cropLand.land.id
          : this.landId;
      // this.productId = item.productKind && item.productKind.product && item.productKind.product.id !== "undefined" ? item.productKind.product.id : this.productId;
    }
  }
};
</script>
